<template>
  <v-container fill-height class="mx-5">
    <Loader :loading="loading" animation="loader" />
    <v-row v-if="!checkRoles">
      <v-img class="animate__animated animate__rollIn img-shadow" :src="logo" max-height="250" contain />
    </v-row>
    <v-row v-else>
      <v-col cols="12" md="10">
        <div class="text-h3">Dashboard de préstamos y pagos</div>
        <div class="text-h6">{{ rangeDates }}</div>
      </v-col>
      <v-col cols="12" md="2" class="text-right">
        <v-btn color="primary" dark large @click="openFilters()">
          Filtros
          <v-icon right>mdi-filter</v-icon>
        </v-btn>
      </v-col>
      <v-col cols="12" md="6">
        <v-card max-height="500" min-width="300" height="100%">
          <v-card-title class="font-weight-bold">
            Préstamos<br/>
          </v-card-title>
          <v-card-subtitle>
            Préstamos del periodo
          </v-card-subtitle>
          <v-card-text>
            <v-row>
              <v-col cols="12" md="4">
                <div class="text-h3" style="color:green;">{{ info.prestamos.activos }}</div>
                <div class="text-h5">Activos</div>
              </v-col>
              <v-col cols="12" md="4">
                <div class="text-h3" style="color:green;">{{ info.prestamos.en_aprobacion }}</div>
                <div class="text-h5">En Aprobación</div>
              </v-col>
              <v-col cols="12" md="4">
                <div class="text-h3" style="color:green;">{{ info.prestamos.rechazados }}</div>
                <div class="text-h5">Rechazados</div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="6">
        <v-card max-height="500" min-width="300" height="100%">
          <v-card-title class="font-weight-bold">
            Pagos<br/>
          </v-card-title>
          <v-card-subtitle>
            Pagos realizados y pendientes del periodo
          </v-card-subtitle>
          <v-card-text>
            <v-row>
              <v-col cols="12" md="4">
                <div class="text-h5">Pagos del día de hoy</div>
              </v-col>
              <v-col cols="12" md="5">
                <v-progress-linear rounded v-model="info.pagos.hoy.porcentaje" color="primary" height="25"></v-progress-linear>
              </v-col>
              <v-col cols="12" md="3">
                <div class="text-h5">$ {{ formatNumberToMoney(info.pagos.hoy.monto) }}</div>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="4">
                <div class="text-h5">Pagos de la semana</div>
              </v-col>
              <v-col cols="12" md="5">
                <v-progress-linear rounded v-model="info.pagos.semana.porcentaje" color="primary" height="25"></v-progress-linear>
              </v-col>
              <v-col cols="12" md="3">
                <div class="text-h5">$ {{ formatNumberToMoney(info.pagos.semana.monto) }}</div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="6">
        <v-card max-height="500" min-width="300" height="100%">
          <v-card-title class="font-weight-bold">
            Total prestado del periodo<br/>
          </v-card-title>
          <v-card-subtitle>
            Total en pesos de prestado activo
          </v-card-subtitle>
          <v-card-text>
            <Line-chart :chart-data="chartDebt.data" :options="chartDebt.options"></Line-chart>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="6">
        <v-card max-height="500" min-width="300" height="100%">
          <v-card-title class="font-weight-bold">
            Grupos con más actividad<br/>
          </v-card-title>
          <v-card-subtitle>
            La actividad se toma de los préstamos registrados
          </v-card-subtitle>
          <v-card-text>
            <Bar-chart :chart-data="chartGroup.data" :options="chartGroup.options"></Bar-chart>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="dialogs.filters" persistent max-width="600px">
      <v-card>
        <v-toolbar dense dark color="secondary" elevation="2">
          <v-toolbar-title>Establecer filtros</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text small :disabled="loading" @click="cancel()">
            Cerrar
            <v-icon right>mdi-close</v-icon>
          </v-btn>
          <v-btn text small :loading="loading" @click="refresh()">
            Aceptar
            <v-icon right>mdi-check</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-form ref="formFilters" lazy-va2lidation>
              <v-row class="mt-2">
                <v-col cols="6">
                  <Date-Picker v-model="forms.filters.fecha_inicio" label="Fecha inicio" :disabled="loading" />
                </v-col>
                <v-col cols="6">
                  <Date-Picker v-model="forms.filters.fecha_fin" label="Fecha fin" :disabled="loading" />
                </v-col>
                <v-col cols="12">
                  <v-autocomplete v-model="forms.filters.grupos" label="Grupos" :items="catalogues.grupos" item-text="nombre" item-value="idgrupo"
                  prepend-icon="mdi-account-multiple" :disabled="loading" multiple small-chips outlined dense required>
                    <template v-slot:selection="{ item, index }">
                      <v-chip v-if="index <= 1" small>
                        <span>{{ item.nombre }}</span>
                      </v-chip>
                      <span v-if="index == 2" class="grey--text text-caption">
                        (+{{ forms.filters.grupos.length - 2 }} más)
                      </span>
                    </template>
                  </v-autocomplete>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="success" color="success" :timeout="2500" bottom right>
      {{ msgSuccess }}
    </v-snackbar>
    <v-snackbar v-model="error" color="error" :timeout="2500" bottom right>
      {{ msgError }}
    </v-snackbar>
  </v-container>
</template>

<script>
import { Loader, DatePicker, LineChart, BarChart } from "@/components"
import services from "@/utils/services"
import moment from 'moment-timezone'
import 'animate.css'

export default {
  name: 'home',
  components: { Loader, DatePicker, LineChart, BarChart },
  data: () => ({
    loading: false,
    success: false,
    msgSuccess: '',
    error: false,
    msgError: '',
    logo: require('@/assets/logo.png'),
    catalogues: {
      grupos: [],
      meses: ['enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre']
    },
    rangeDates: '',
    info: {
      prestamos: {
        activos: 0,
        en_aprobacion: 0,
        rechazados: 0
      },
      total_prestado: [],
      grupos: {},
      pagos: {
        hoy: {
          porcentaje: 0,
          monto: 0
        },
        semana: {
          porcentaje: 0,
          monto: 0
        }
      }
    },
    forms: {
      filters: {
        fecha_inicio: null,
        fecha_fin: null,
        grupos: [],
      }
    },
    chartDebt: {
      data: null,
      options: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: false
        }
      }
    },
    chartGroup: {
      data: null,
      options: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: false
        }
      }
    },
    dialogs: {
      filters: false
    }
  }),
  mounted() {
    this.getCatalogues()
    this.setFilters()
    this.refresh()
  },
  computed: {
    checkRoles: function () {
      for (let rol of this.$session.get('roles')) {
        if (rol === 'administrador' || rol === 'coordinador general' || rol === 'entrega') {
          return true;
        }
      }
      return false
    }
  },
  methods: {
    async getCatalogues() {
      const group = await this.axios.get(services.routes.group)
      if (group.data.data) {
        this.catalogues.grupos = group.data.data
      }
    },
    setFilters() {
      let initDate = moment.tz('America/Monterrey');
      if (initDate.day() == 7) {
        initDate.subtract(1, 'day')
      }
      const date = initDate.format('YYYY-MM-DD')
      const startDate = moment.tz(date, 'America/Monterrey').startOf('week').add(1, 'day').format('YYYY-MM-DD');
      const endDate = moment.tz(date, 'America/Monterrey').endOf('week').add(1, 'day').format('YYYY-MM-DD');
      this.forms.filters.fecha_inicio = startDate
      this.forms.filters.fecha_fin = endDate
      const startMonth = moment.tz(startDate, 'America/Monterrey').month()
      const endMonth = moment.tz(endDate, 'America/Monterrey').month()
      this.rangeDates = `${startDate.split('-')[2]} de ${this.catalogues.meses[startMonth]} de ${startDate.split('-')[0]} - ` +
      `${endDate.split('-')[2]} de ${this.catalogues.meses[endMonth]} de ${endDate.split('-')[0]}`
    },
    updateDates() {
      const startDate = this.forms.filters.fecha_inicio
      const endDate = this.forms.filters.fecha_fin
      const startMonth = moment.tz(this.forms.filters.fecha_inicio, 'America/Monterrey').month()
      const endMonth = moment.tz(this.forms.filters.fecha_fin, 'America/Monterrey').month()
      this.rangeDates = `${startDate.split('-')[2]} de ${this.catalogues.meses[startMonth]} de ${startDate.split('-')[0]} - ` +
      `${endDate.split('-')[2]} de ${this.catalogues.meses[endMonth]} de ${endDate.split('-')[0]}`
    },
    refresh() {
      this.updateDates()
      this.loading = true
      let params = {
        fecha_inicio: this.forms.filters.fecha_inicio,
        fecha_fin: this.forms.filters.fecha_fin,
        grupos: this.forms.filters.grupos
      }
      this.axios.get(services.routes.dashboardReport, { params })
        .then((response) => {
          this.info = response.data.data
          this.prepareChartDebt(this.info.total_prestado)
          this.prepareChartGroup(this.info.grupos)
          this.cancel()
        })
        .catch((error) => {
          this.alert.active = true
          this.alert.color = 'error'
          this.alert.msg = error.response.data.message || 'Servicio no disponible'
        })
        .finally(() => {
          this.loading = false
        })
    },
    clear() {
      this.forms.filters.fecha_inicio = null
      this.forms.filters.fecha_fin = null
      this.forms.filters.grupos = []
    },
    cancel() {
      this.dialogs.filters = false
    },
    openFilters() {
      this.cancel()
      this.dialogs.filters = true
    },
    prepareChartDebt(arr) {
      let labels = []
      let data = []
      for (let elem of arr) {
        let idx = labels.indexOf(elem.fecha)
        if (idx < 0) {
          labels.push(elem.fecha)
          data.push(0)
          idx = 0
        }
        data[idx] += elem.monto
      }
      this.chartDebt.data = {
        labels: labels,
        datasets: [{
          borderColor: '#2196F3',
          fill: false,
          data: data,
          borderWidth: 1,
          pointRadius: 5,
        }]
      }
    },
    prepareChartGroup(group) {
      let labels = []
      let data = []
      let sortable = []
      for (let key in group) {
        sortable.push([key, group[key].prestamos])
      }
      sortable.sort(function(a, b) {
        return a[1] - b[1]
      })
      sortable.reverse()
      let datasets = []
      for (let i = 0; i < sortable.length && i < 5; i++) {
        const key = sortable[i][0]
        labels.push(key);
        const monto_prestado = group[key].monto_prestado
        const monto_pagado = group[key].monto_pagado
        const monto_pendiente = group[key].monto_pendiente
        data.push([
          monto_prestado,
          monto_pagado,
          monto_pendiente
        ])
      }
      let data1 = []
      let data2 = []
      let data3 = []
      for (let i = 0; i < labels.length; i++) {
        data1.push(data[i][0])
        data2.push(data[i][1])
        data3.push(data[i][2])
      }
      datasets.push({
        label: 'Dinero prestado',
        data: data1,
        backgroundColor: [
          'rgba(255, 87, 34, 0.8)',
          'rgba(255, 159, 64, 0.8)',
          'rgba(255, 205, 86, 0.8)',
          'rgba(30, 150, 243, 0.8)',
          'rgba(103, 58, 183, 0.8)',
        ],
      })
      datasets.push({
        label: 'Dinero pagado',
        data: data2,
        backgroundColor: [
          'rgba(255, 87, 34, 0.8)',
          'rgba(255, 159, 64, 0.8)',
          'rgba(255, 205, 86, 0.8)',
          'rgba(30, 150, 243, 0.8)',
          'rgba(103, 58, 183, 0.8)',
        ],
      })
      datasets.push({
        label: 'Por recuperar',
        data: data3,
        backgroundColor: [
          'rgba(255, 87, 34, 0.8)',
          'rgba(255, 159, 64, 0.8)',
          'rgba(255, 205, 86, 0.8)',
          'rgba(30, 150, 243, 0.8)',
          'rgba(103, 58, 183, 0.8)',
        ],
      })
      this.chartGroup.data = {
        labels: labels,
        datasets: datasets
      }
    },
    formatNumberToMoney(num) {
      try {
        const aux = Number(num);
        return aux
          .toFixed(2)
          .toString()
          .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
      } catch (e) {
        return ''
      }
    },
  },
};
</script>

<style scoped>
</style>
